<template>
    <div class="product-edit-list-wrapper">
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
            >
                <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
                >
                    <b-row v-if="!isLoading">
                        <b-col cols="9">
                            <b-card>
                                <b-form @submit.prevent="create">
                                    <b-row>

                                        <!-- name -->
                                        <b-col cols="12">
                                        <b-form-group
                                            :label="$t('Name')"
                                            label-for="v-name"
                                        >
                                            <b-form-input
                                            id="v-name"
                                            :placeholder="$t('Name')"
                                            v-model="product.name"
                                            />
                                        </b-form-group>
                                        </b-col>

                                        <!-- type -->
                                        <b-col cols="6">
                                        <b-form-group
                                            :label="$t('Type')"
                                            label-for="v-type"
                                        >
                                            <b-form-select
                                            id="v-type"
                                            v-model="product.product_type"
                                            :options="productTypeOptions"
                                            />
                                        </b-form-group>
                                        </b-col>

                                        <!-- product code -->
                                        <b-col cols="6">
                                        <b-form-group
                                            :label="$t('Product Code')"
                                            label-for="v-product_code"
                                        >
                                            <b-form-input
                                            id="v-product_code"
                                            placeholder="SA023"
                                            v-model="product.product_code"
                                            />
                                        </b-form-group>
                                        </b-col>

                                        <!-- quantity -->
                                        <b-col cols="6">
                                        <b-form-group
                                            :label="$t('Quantity')"
                                            label-for="v-quantity"
                                        >
                                            <b-form-input
                                            id="v-quantity"
                                            type="text"
                                            placeholder="100"
                                            v-model="product.quantity"
                                            />
                                        </b-form-group>
                                        </b-col>

                                        <!-- cost price -->
                                        <b-col cols="6">
                                        <b-form-group
                                            :label="$t('Cost Price')"
                                            label-for="v-cost_price"
                                        >
                                            <b-input-group>
                                            <b-form-input
                                                id="v-cost_price"
                                                placeholder="30"
                                                v-model="product.cost_price"
                                            />
                                            <b-input-group-append is-text>
                                                {{$t('SAR')}}
                                            </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                        </b-col>

                                        <!-- original_price -->
                                        <b-col cols="6">
                                        <b-form-group
                                            :label="$t('Original Price')"
                                            label-for="v-original_price"
                                        >
                                            <b-input-group>
                                            <b-form-input
                                                id="v-original_price"
                                                placeholder="45"
                                                v-model="product.original_price"
                                            />
                                            <b-input-group-append is-text>
                                                {{$t('SAR')}}
                                            </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                        </b-col>

                                        <!-- price_after_discount -->
                                        <b-col cols="6">
                                        <b-form-group
                                            :label="$t('Price After Discount')"
                                            label-for="v-price_after_discount"
                                        >
                                            <b-input-group>
                                            <b-form-input
                                                id="v-price_after_discount"
                                                placeholder="40"
                                                v-model="product.price_after_discount"
                                            />
                                            <b-input-group-append is-text>
                                                {{$t('SAR')}}
                                            </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                        </b-col>

                                        <!-- categories -->
                                        <b-col cols="6">
                                        <b-form-group
                                            :label="$t('Categories')"
                                            label-for="v-categories"
                                        >
                                            <v-select
                                            v-model="product.categories"
                                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                            multiple
                                            label="name"
                                            :options="categories"
                                            />
                                        </b-form-group>
                                        </b-col>

                                        <!-- default -->
                                        <b-col md="6">
                                        <b-form-group
                                            :label="$t('Availability Date')"
                                            label-for="v-availability_date"
                                        >
                                            <flat-pickr
                                            v-model="product.availability_date"
                                            class="form-control"
                                            />
                                        </b-form-group>
                                        </b-col>

                                        <!-- default -->
                                        <b-col md="6">
                                        <b-form-group
                                            :label="$t('Available?')"
                                            label-for="v-availability"
                                        >
                                            <b-form-checkbox
                                            :checked="product.availability == 1 ? true : false"
                                            class="custom-control-primary"
                                            name="availability"
                                            switch
                                            v-model="product.availability"
                                            >
                                            <span class="switch-icon-left">
                                                <feather-icon icon="CheckIcon" />
                                            </span>
                                            <span class="switch-icon-right">
                                                <feather-icon icon="XIcon" />
                                            </span>
                                            </b-form-checkbox>
                                        </b-form-group>
                                        </b-col>

                                        <!-- submit -->
                                        <b-col cols="12">
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            type="submit"
                                            variant="gradient-primary"
                                            class="mr-1"
                                        >
                                            <template v-if="!isLoadingSave">
                                            {{$t('Save')}}
                                            </template>
                                            <template v-if="isLoadingSave">
                                            {{$t('Saveing')}}...
                                            <b-spinner small />
                                            </template>
                                        </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-card>
                        </b-col>
                    </b-row>
                </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
    directives: {
    Ripple,
  },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            product: {},
            categories: [],
            isLoading: true,
            isLoadingSave: false,
            productTypeOptions: [
                { value: 'product', text: this.$t('Product') },
                { value: 'file', text: this.$t('Digital') },
                { value: 'service', text: this.$t('Service') },
            ],
        }
    },
    methods: {
        getResults() {
            useJwt.get('/categories/get-all')
            .then((response) => {
                console.log(response.data)
                this.categories = response.data.data.data
                this.isLoading = false
            })
            .catch(response => {
                console.log(response);
            });
        },
        create() {
            this.isLoadingSave = true;
            this.product.store_id = this.$store.state.store.id;
            useJwt.post('/products/store',this.product)
            .then((response) => {
                // console.log(response.data);
                this.isLoadingSave = false
                this.$root.showToast('success', 'save', '')
                this.$router.push({ name: 'products' })
            })
            .catch(error => {
                // console.log(response);
                this.isLoadingSave = false
                this.$root.showAlert('error', error.response.data.message)
            });
        }
    },

};
</script>
<style lang="scss">
// @import "~@core/scss/base/pages/app-todo.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>